import { FC } from "react";
import { Skeleton, SxProps, TextField } from "@mui/material";
import Logger from "../lib/logger";
import { useGetHyperionWebBuildsQuery } from "../../store/services/hyperionWebBuilds";

const SelectableHyperionWebVersionInput: FC<{
  hyperionWebVersion: string;
  setHyperionWebVersion: (str: string) => void;
  hypVerOptions: { version: string }[];
  sx?: SxProps;
}> = (props) => {

  return (
    <TextField
      sx={props.sx}
      fullWidth
      label="Hyperion Web Version"
      name="hypVer"
      select
      SelectProps={{ native: true }}
      value={props.hyperionWebVersion}
      onChange={(e: any) => props.setHyperionWebVersion(e.target.value)}
    >
      {props.hypVerOptions.map((option) => (
        <option key={option.version} value={option.version}>
          {option.version}
        </option>
      ))}
    </TextField>
  );
};

const SelectableHyperionWebVersion: FC<{
  hyperionWebVersion: string;
  setHyperionWebVersion: (str: string) => void;
  sx?: SxProps;
}> = (props) => {

  const {
    error: errorHypWebBuild,
    data: datarHypWebBuild,
    isFetching: isFetchingHypWebBuilds,
  } = useGetHyperionWebBuildsQuery();

  if (errorHypWebBuild) {
    Logger.debug({ prefix: "Component Error", msg: "Error retrieving available HyperionWeb versions" });
  }

  let hypVerOptions = [{ version: "latest" }];
  if (datarHypWebBuild?.data) {
    hypVerOptions = [...hypVerOptions, ...datarHypWebBuild.data];
  }

  return (
    <>
      {
        isFetchingHypWebBuilds ? (
          <Skeleton variant="text" width={"100%"} height={60} sx={props.sx} />
        ) : (
          <SelectableHyperionWebVersionInput
            sx={props.sx}
            hyperionWebVersion={props.hyperionWebVersion}
            setHyperionWebVersion={props.setHyperionWebVersion}
            hypVerOptions={hypVerOptions}
          />
        )}
    </>
  );
};

export default SelectableHyperionWebVersion;
