import { Delete } from "@mui/icons-material";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { SDKRuntimeConfigurations } from "../pages/HyperionIntegrationTestPage";
import SelectableHyperionWebVersion from "../ShareWithDash/components/SelectableHyperionWebVersion";
import Plus from "../ShareWithDash/icons/Plus";
import { generateExperienceLink } from "../ShareWithDash/lib/generateExperienceLink";

const SDKOptionsDialog: FC<{
  onUpdate: (sdkConfigs: SDKRuntimeConfigurations) => void,
  children?: ReactNode,
  mountNode?: string | HTMLElement,
  onOpen: () => void,
  onClose: () => void,
  isOpen: boolean,
}> = ({ onUpdate, onClose, onOpen, isOpen, children, mountNode }) => {

  // Support queryparams to set default values.
  const [searchParams, setSearchParams] = useSearchParams();


  // We could pull in formik eventually and redo this,
  // but for now let's just use easy state hooks
  //
  // initializeSDK fields
  const [projectId, setProjectId] = useState(searchParams.get('projectId') ?? "");
  const [appKey, setAppKey] = useState(searchParams.get('appKey') ?? "");
  // TODO (https://illumix.atlassian.net/browse/WEB-516): automatically set from current user's user.id
  // if no queryparam set.
  const [userId, setUserId] = useState(searchParams.get('userId') ?? "");
  const [autoDispose, setAutoDispose] = useState(searchParams.get('autoDispose') === 'true' || false);
  const autoDisposeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoDispose(event.target.checked);
  }
  // start & preload fields
  const [sku, setSKU] = useState(searchParams.get('sku') ?? "");
  const [relatedSKUs, setRelatedSKUs] = useState(searchParams.get('relatedSKUs')?.split(',') || new Array<string>());
  // utility func to bridge state hook and input onChange event.
  // I'd expect React to do this automagically but it doesn't seem to...
  const handleSimpleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    setter(event.target.value);
  }
  const [hypWebVersion, setHypWebVersion] = useState(searchParams.get('hypWebVersion') || 'latest');
  //\\

  useEffect(() => {
    // Note: if we change any of these keys, note that anyone's links they've bookmarked may break. Best to add
    // a deprecation
    setSearchParams((params) => {
      if (projectId) params.set('projectId', projectId);
      if (appKey) params.set('appKey', appKey);
      if (userId) params.set('userId', userId);
      if (sku) params.set('sku', sku);
      params.set('autoDispose', autoDispose.toString());
      if (relatedSKUs?.length) params.set('relatedSKUs', relatedSKUs.join(','));
      if (hypWebVersion) params.set('hypWebVersion', hypWebVersion);
      return params;
    });
  }, [projectId, appKey, userId, autoDispose, sku, relatedSKUs, hypWebVersion, setSearchParams])

  const handleUpdate = () => {
    const sdkConfigs: SDKRuntimeConfigurations = {
      initializeSDK: [{
        project_id: projectId,
        app_key: appKey,
        auto_dispose: autoDispose,
        experience_url_override: generateExperienceLink(hypWebVersion)
      }],
      start: [{
        sku: sku,
        relatedSKUs: relatedSKUs,
        mountNode: mountNode
      }],
      preload: [{
        sku: sku,
        relatedSKUs: relatedSKUs,
        mountNode: mountNode
      }]
    };

    onUpdate(sdkConfigs);
    onClose();
  }
  const addRelatedSKU = () => {
    setRelatedSKUs([...relatedSKUs, ""])
  }
  const updateRelatedSKU = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    relatedSKUs[index] = event.target.value;
    setRelatedSKUs([...relatedSKUs]);
  }
  const deleteRelatedSKU = (index: number) => {
    setRelatedSKUs(relatedSKUs.filter((v, i) => i !== index));
  }
  return (
    <>
      <Button onClick={onOpen}>{children}</Button>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Edit SDK Options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set SDK Options and then click &quot;Update Experience&quot; to restart experience with the new values.
          </DialogContentText>
          <Divider sx={{ my: 2 }} />
          <Typography variant='h5'>Initialize SDK Options</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="project_id"
            value={projectId}
            onChange={(e) => handleSimpleChange(e, setProjectId)}
            label="Project Id"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="app_key"
            value={appKey}
            onChange={(e) => handleSimpleChange(e, setAppKey)}
            label="App Key"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="user_id"
            value={userId}
            onChange={(e) => handleSimpleChange(e, setUserId)}
            label="User Id (Fill current user Id?)"
            type="text"
            fullWidth
            variant="standard"
          />
          <FormGroup>
            <FormControlLabel sx={{ my: 2 }} control={<Checkbox checked={autoDispose} onChange={autoDisposeOnChange} />} label="Auto Dispose" />
          </FormGroup>
          <SelectableHyperionWebVersion
            hyperionWebVersion={hypWebVersion}
            setHyperionWebVersion={(hypVer) =>
              setHypWebVersion(hypVer)
            }
          />
          <Divider sx={{ my: 4 }} />
          <Typography variant='h5'>Start & Preload Options</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="start_sku"
            value={sku}
            onChange={(e) => handleSimpleChange(e, setSKU)}
            label="SKU"
            type="text"
            fullWidth
            variant="standard"
          />
          <FormGroup sx={{ my: 2 }}>
            <Button endIcon={<Plus></Plus>} onClick={addRelatedSKU}>Related SKUs</Button>
          </FormGroup>
          {relatedSKUs.map((relatedSKU, i) => (
            <Box key={`start_related_sku_${i}`} sx={{ display: "flex" }}>
              <TextField
                autoFocus
                margin="dense"
                id={`start_related_sku_${i}`}
                value={relatedSKUs[i]}
                onChange={(e) => updateRelatedSKU(i, e)}
                label={`Related SKU ${i + 1}`}
                type="text"
                fullWidth
                variant="standard"
              // endAdornment
              />
              <Button onClick={() => { deleteRelatedSKU(i) }}><Delete></Delete></Button>
            </Box>
          )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleUpdate}>Update Experience</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SDKOptionsDialog;