import { FC } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const Authorize: FC = () => {
  const navigate = useNavigate()

  const auth = useAuth0()

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(`/`)
    } else {
      auth.loginWithRedirect({
        redirectUri: window.location.origin
      })
    }
  }, [auth, navigate])

  return <></>
}

export default Authorize
