import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { ThunkAction } from "redux-thunk";
import {
  configureStore,
  combineReducers,
  Middleware,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { hyperionWebBuildsApi } from "./services/hyperionWebBuilds";
import Logger from "../ShareWithDash/lib/logger";

const rootReducer = combineReducers({
  [hyperionWebBuildsApi.reducerPath]: hyperionWebBuildsApi.reducer,
});

export const rtkQueryErrorLogger: Middleware =
  () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      Logger.error({ prefix: "rtkQueryErrorLogger.rejected", msg: action });
    }

    return next(action);
  };

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      hyperionWebBuildsApi.middleware,
      rtkQueryErrorLogger
    ),
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
