import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider as ReduxProvider } from "react-redux";
import { auth0Config } from './config'
import store from './store'

const root = createRoot(document.getElementById('root')!) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.client_id}
        audience={auth0Config.audience}
        redirectUri={window.location.origin}
        cacheLocation={'localstorage'}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </ReduxProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
