import {
  BaseQueryFn,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";

import axios from "../../ShareWithDash/lib/axios";
import { serverConfig } from "../../config";
import { getLocalStorage } from "../../ShareWithDash/lib/localStorage";
import { ApiError } from "../../ShareWithDash/types/response";

const baseQuery = fetchBaseQuery({
  baseUrl: `${serverConfig.url}/${serverConfig.version}`,
  prepareHeaders: (headers) => {
    const token = getLocalStorage({ key: "accessToken" });
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      headers?: AxiosRequestConfig["headers"] & { [k: string]: string };
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, headers }) => {
      try {
        const token = getLocalStorage({ key: "accessToken" });
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        axios.defaults.headers.common["Content-Type"] = "application/json";

        const result = await axios({
          url: baseUrl + url,
          method,
          data,
          headers: headers ? headers : {},
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError as AxiosError<ApiError>;

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data,
          },
        };
      }
    };
