import { useCallback, useEffect, useState } from "react";
import type { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../lib/localStorage";

import SplashScreen from "./SplashScreen";
import Logger from "../lib/logger";

const Redirector: FC = () => {
  const navigate = useNavigate();
  const auth = useAuth0();

  const [, setFreshTokenSaved] = useState(false);
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const redirectSearch = searchParams.get('search');

  const setStorageToken = useCallback(async () => {
    await auth
      .getAccessTokenSilently()
      .then((token) => setLocalStorage({ key: "accessToken", value: token }));
  }, [auth]);

  const userAuthenticated = auth.isAuthenticated === true;
  const userExists = auth.user != null;
  const tokenInStorage = getLocalStorage({ key: "accessToken" }) != null;
  const authError = auth.error;


  useEffect(() => {
    if (userAuthenticated) {
      setStorageToken();
    }
  }, [userAuthenticated, setStorageToken]);

  useEffect(() => {
    Logger.debug({
      prefix: "Redirector.useEffect.params",
      msg: {
        userAuthenticated: userAuthenticated,
        userExists: userExists,
        tokenInStorage: tokenInStorage,
        authError: authError,
      },
    });

    if (authError) {
      Logger.error({ prefix: "Auth0.error", msg: authError });
      navigate(`/home`);
    } else if (userExists && userAuthenticated) {
      Logger.debug({ prefix: "Redirector", msg: "User has been authenticated" });
      setStorageToken().then(() => setFreshTokenSaved(true));
      if (redirect) {
        Logger.debug({ prefix: "Redirecting", msg: `Redirecting to: ${redirect}` });
        navigate({ pathname: redirect, search: redirectSearch ? redirectSearch : '' });
      } else {
        Logger.debug({ prefix: "Redirector", msg: "Redirecting to /home" });
        navigate('/home')
      }
    } else if (!userAuthenticated && !auth.isLoading) {
      removeLocalStorage({ key: "accessToken" });
      navigate(`/home`);
    }
  }, [
    userAuthenticated,
    userExists,
    authError,
    tokenInStorage,
    setStorageToken,
    navigate,
    searchParams,
    redirect,
    redirectSearch,
    auth
  ]);

  return (
    <>
      <SplashScreen />
    </>
  );
};

export default Redirector;
